export default function Quicklinks() {
  return (
    <div className="quicklinks">
      <a href="tel:0857608833">
        <i className="fas fa-phone" />
      </a>
      <a href="mailto:info@wijsamsam.nl">
        <i className="fas fa-envelope" />
      </a>
    </div>
  );
}
