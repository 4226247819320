import { useContext } from "react";
import GeneralContext from "../store/general";
import Link from "next/link";

export default function MobileMenu({ menus }) {
  const generalContext = useContext(GeneralContext);

  function toggleMobileMenu() {
    generalContext.toggleMobileMenu();
  }

  return (
    <div
      className={`mobile-menu ${
        generalContext.mobileMenuOpen ? "mobile-menu--is-open" : ""
      }`}
    >
      <button onClick={toggleMobileMenu} className="mobile-menu__close">
        <i className="fas fa-times" />
      </button>
      <div className="mobile-menu__background-logo" />
      <div className="mobile-menu__content">
        <Link href="/">
          <a className="mobile-menu__logo" onClick={toggleMobileMenu} />
        </Link>
        <ul>
          {menus["mobile-menu"].items.map((item) => {
            return (
              <li key={item.url}>
                <Link href={item.url} target={item.target}>
                  <a onClick={toggleMobileMenu}>{item.title}</a>
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="mt-20">
          <a
            href="https://mijnomgeving.wijsamsam.nl/"
            target="_blank"
            className="mobile-menu__account-link"
          >
            <i className="fas fa-user-circle" />
            <span>Mijn SamSam</span>
          </a>
        </div>
      </div>
    </div>
  );
}
