import NavbarDesktop from "./navbar-desktop";
import NavbarMobile from "./navbar-mobile";
import MobileMenu from "./mobile-menu";
import Quicklinks from "./quicklinks";

export default function Header({ menus }) {
  return (
    <header>
      <NavbarDesktop menus={menus} />
      <NavbarMobile />
      <MobileMenu menus={menus} />
      <Quicklinks />
    </header>
  );
}
