import "../styles/style.scss";
import Layout from "../layouts/default";
import { GeneralContextProvider } from "../store/general";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { gtmConfig } from "../config";
import { gtm } from "../lib/gtm";
import { useRouter } from "next/router";

const ProgressBar = dynamic(() => import("../components/progress-bar"), {
  ssr: false,
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtm.push({ event: "virtual_page_view", page: url });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const menus = {
    "main-menu-left": {
      items: [
        {
          title: "Groene energie",
          url: "/groene-energie",
          target: "",
        },
        {
          title: "Zonnepanelen",
          url: "/zonnepanelen",
          target: "",
        },
        {
          title: "Impact",
          url: "/impact",
          target: "",
        },
      ],
    },
    "main-menu-right": {
      items: [
        {
          title: "Over ons",
          url: "/over-ons",
          target: "",
        },
        {
          title: "Blog",
          url: "/blog",
          target: "",
        },
        {
          title: "Mijn SamSam",
          url: "https://mijnomgeving.wijsamsam.nl/",
          target: "_blank",
        },
      ],
    },
    "mobile-menu": {
      items: [
        {
          title: "Groene energie",
          url: "/groene-energie",
          target: "",
        },
        {
          title: "Zonnepanelen",
          url: "/zonnepanelen",
          target: "",
        },
        {
          title: "Impact",
          url: "/impact",
          target: "",
        },
        {
          title: "Over ons",
          url: "/over-ons",
          target: "",
        },
        {
          title: "Blog",
          url: "/blog",
          target: "",
        },
      ],
    },
  };

  return (
    <GeneralContextProvider>
      <ProgressBar />
      <Layout menus={menus} page={pageProps.page ? pageProps.page : null}>
        <Component {...pageProps} />
      </Layout>
    </GeneralContextProvider>
  );
}

export default MyApp;
