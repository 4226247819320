import Link from "next/link";

export default function MainFooter({ footer }) {
  return (
    <div className="main-footer">
      <Link href="/">
        <a className="main-footer__logo" />
      </Link>
      <div className="container">
        <div className="frame">
          <div className="bit-xxs-12">
            {footer.main_footer.slogan && (
              <p className="main-footer__slogan">{footer.main_footer.slogan}</p>
            )}
            <div className="main-footer__socials flex flex--justify-center">
              {footer.main_footer.instagram && (
                <a href={footer.main_footer.instagram} target="_blank">
                  <i className="fab fa-instagram mr-20" />
                </a>
              )}
              {footer.main_footer.facebook && (
                <a href={footer.main_footer.facebook} target="_blank">
                  <i className="fab fa-facebook" />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="frame frame--justify-sm-center">
          <div className="bit-xxs-12 bit-sm-5 bit-md-3">
            {footer.main_footer.address_and_contact && (
              <div
                dangerouslySetInnerHTML={{
                  __html: footer.main_footer.address_and_contact,
                }}
              />
            )}
          </div>
          <div className="bit-xxs-12 bit-sm-5 bit-md-3 hidden-mobile">
            <div className="main-footer__menu-list">
              <p className="pb-0">
                <strong>{footer.main_footer.quicklinks_menu_title}</strong>
              </p>
              {footer.main_footer.quicklinks_menu_list.length &&
                footer.main_footer.quicklinks_menu_list.map((item) => {
                  item = item.link;
                  return (
                    <p className="pb-0" key={item.title}>
                      <Link href={item.url} passHref>
                        <a target={item.target}>{item.title}</a>
                      </Link>
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="bit-xxs-12 bit-sm-5 bit-md-3">
            <div className="main-footer__menu-list">
              <p className="pb-0">
                <strong>{footer.main_footer.about_menu_title}</strong>
              </p>
              {footer.main_footer.about_menu_list.length &&
                footer.main_footer.about_menu_list.map((item) => {
                  item = item.link;
                  return (
                    <p className="pb-0" key={item.title}>
                      <Link href={item.url} passHref>
                        <a target={item.target}>{item.title}</a>
                      </Link>
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="bit-xxs-12 bit-sm-5 bit-md-3">
            <p className="pb-20">
              <strong>{footer.main_footer.reviews_title}</strong>
            </p>
            {footer.main_footer.klantenvertellen_url && (
              <a
                href={footer.main_footer.klantenvertellen_url}
                target="_blank"
                className="main-footer__klantenvertellen-logo mb-10"
              />
            )}
            <p className="text--lineheight-tight">
              <small>
                <strong
                  dangerouslySetInnerHTML={{
                    __html: footer.main_footer.reviews_text,
                  }}
                />
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
