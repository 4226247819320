import PreFooter from "./pre-footer";
import MainFooter from "./main-footer";
import PostFooter from "./post-footer";

export default function Footer({ footer }) {
  return (
    <>
      <footer>
        <PreFooter footer={footer} />
        <MainFooter footer={footer} />
        <PostFooter footer={footer} />
      </footer>
    </>
  );
}
